






































































































































































































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import DrugName from '~/components/DrugName.vue';

@Component<ISIInfo>({
    components: {
        DrugName,
    },
})
export default class ISIInfo extends Vue {}
