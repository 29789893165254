// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("static/hero-desktop.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".half-circle-spinner .circle-1[data-v-50397314]{border-top-color:#76bc21!important}.half-circle-spinner .circle-2[data-v-50397314]{border-bottom-color:#fce200!important}.bg-image[data-v-50397314]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.hero[data-v-50397314]{min-height:40rem;background-color:#ffe900;background-position:25vw -100px;background-repeat:no-repeat;background-size:cover}.hero .logo[data-v-50397314]{height:7em}.hero .header-info-links li[data-v-50397314]{display:inline;padding:0 5px}.big-btn[data-v-50397314],.hero .header-info-links li a[data-v-50397314]{text-decoration:none}.big-btn[data-v-50397314]{color:#fff;font-weight:700;font-size:1.5rem;border-radius:0}@media only screen and (min-width:2200px){.hero[data-v-50397314]{background-image:none;background-color:#ffe900}}@media only screen and (min-width:1904px){.hero[data-v-50397314]{background-position:right -25vw bottom 0}}@media only screen and (min-width:960px) and (max-width:1263px){.hero[data-v-50397314]{background-position:right 100% bottom 0}}@media only screen and (min-width:780px) and (max-width:959px){.hero[data-v-50397314]{background-position:right 79% bottom 0}.hero .header-info-links li[data-v-50397314]{display:block;text-align:center}}@media only screen and (min-width:600px) and (max-width:779px){.hero[data-v-50397314]{background-position:right 65% bottom 0}.hero .header-info-links li[data-v-50397314]{display:block;text-align:center}}@media only screen and (max-width:599px){.hero .header-info-links li[data-v-50397314]{display:block;text-align:center;padding-top:0}.full-width[data-v-50397314]{width:100%}}", ""]);
// Exports
module.exports = exports;
