























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component<Footer>({})
export default class Footer extends Vue {
    @Prop()
    public brkPoints!: object;
}
