export class Settings {
    public constructor(settings: any) {
        Object.assign(this, settings);
    }
}
export interface Settings {
    base: string;
    url(url: string): string;
    name: string;
    author: string;
    description: string;
    minDate: string;
    mainMenu: Array<{ icon: [string, string]; title: string; to: string; exact: boolean }>;
    errorPages: {
        default: { icon: [string, string]; title: string; message: string; statusCode: number };
        [code: string]: { icon: [string, string]; title: string; message: string; statusCode: number };
    };
    programId: string;
    hubSurveyId: string;
    patientSurveyId: string;
    hubDrPrescribedQuestionId: string;
    hubDrPrescribedAnswerId: string;
    hubDoesLiveInUSQuestionId: string;
    hubDoesLiveInUSAnswerId: string;
    hubNotFederalQuestionId: string;
    hubNotFederalAnswerId: string;
    hubNoReimbursementQuestionId: string;
    hubNoReimbursementAnswerId: string;
    hubAgreeToTCQuestionId: string;
    hubAgreeToTCAnswerId: string;
    hubAttestationQuestionId: string;
    hubAttestationAnswerId: string;
    hubMarketingOptInQuestionId: string;
    hubMarketingOptInYesAnswerId: string;
    hubMarketingOptInNoAnswerId: string;
    hubHaveACardQuestionId: string;
    hubHaveACardYesAnswerId: string;
    hubHaveACardNoAnswerId: string;
    hubCarePartnerZipQuestionId: string;
    hubCarePartnerZipAnswerId: string;
    hubCarePartnerEmailQuestionId: string;
    hubCarePartnerEmailAnswerId: string;
    myNeurelisId: string;
    patientDrPrescribedQuestionId: string;
    patientDrPrescribedAnswerId: string;
    patientDoesLiveInUSQuestionId: string;
    patientDoesLiveInUSAnswerId: string;
    patientNotFederalQuestionId: string;
    patientNotFederalAnswerId: string;
    patientNoReimbursementQuestionId: string;
    patientNoReimbursementAnswerId: string;
    patientAgreeToTCQuestionId: string;
    patientAgreeToTCAnswerId: string;
    patientAttestationQuestionId: string;
    patientAttestationAnswerId: string;
    patientMarketingOptInQuestionId: string;
    patientMarketingOptInYesAnswerId: string;
    patientMarketingOptInNoAnswerId: string;
    patientHaveACardQuestionId: string;
    patientHaveACardYesAnswerId: string;
    patientHaveACardNoAnswerId: string;
    lockEnabled: string;
    lockPassword: string;
    patientCarePartnerZipQuestionId: string;
    patientCarePartnerZipAnswerId: string;
    patientCarePartnerEmailQuestionId: string;
    patientCarePartnerEmailAnswerId: string;
    pharmaApiUrl: string;
    fingerpaintAuthApiUrl: string;
    clientId: string;
    clientSecret: string;
}
