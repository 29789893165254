import { Mutation } from 'vuex-module-decorators';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';

@InjectModule({ stateFactory: true }, module)
export default class HubModule extends InjectVuexModule {
    path = '';
    isHacOrNac = '';
    isAuthorized = false;

    @Mutation
    setPath(path: 'hub' | 'patient') {
        this.path = path;
    }

    @Mutation
    setIsHacOrNac(haveOrNeed: 'HAVE' | 'NEED' | 'have-a-card' | 'need-a-card') {
        const isHacOrNac = ['HAVE', 'have-a-card'].includes(haveOrNeed) ? 'have-a-card' : 'need-a-card';
        this.isHacOrNac = isHacOrNac;
    }

    @Mutation
    setIsAuthorized(isAuthorized: boolean) {
        this.isAuthorized = isAuthorized;
    }
}
