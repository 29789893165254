import { Mutation, Action } from 'vuex-module-decorators';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { Resolve } from 'vue-di';
import { SurveySessionValueModel } from '@trialcard/apigateway.models';
import SurveyModule from './survey';
import SessionModule from './session';
import HubModule from './hub';
import { Settings } from '~/services/settings';
import { EligibilityQuestions, correctAnswers } from '~/types/eligibility';

@InjectModule({ stateFactory: true }, module)
export default class EligibilityModule extends InjectVuexModule {
    @Resolve
    public settings!: Settings;

    @Resolve
    public survey!: SurveyModule;

    @Resolve
    public session!: SessionModule;

    @Resolve
    public hub!: HubModule;

    public patientEligibilityData: EligibilityQuestions = {};
    public hubEligibilityData: EligibilityQuestions = {};

    public isEligible = false;

    @Mutation
    public async updatePatientEligibilityData(newEligibilityData: typeof EligibilityModule.prototype.patientEligibilityData) {
        this.patientEligibilityData = newEligibilityData;
    }
    @Mutation
    public async updateHubEligibilityData(newEligibilityData: typeof EligibilityModule.prototype.hubEligibilityData) {
        this.hubEligibilityData = newEligibilityData;
    }

    @Mutation
    public async updateIsEligible(isEligible: boolean) {
        this.isEligible = isEligible;
    }

    @Mutation
    public async clearEligibilityData() {
        this.patientEligibilityData = {};
        this.hubEligibilityData = {};
    }

    @Action({ rawError: true })
    public async checkEligibility(): Promise<boolean> {
        const correctAnswers: correctAnswers = {
            agreeToTC: +this.settings[`${this.hub.path}AgreeToTCAnswerId` as keyof Settings],
            attestation: +this.settings[`${this.hub.path}AttestationAnswerId` as keyof Settings],
            doesLiveInUS: +this.settings[`${this.hub.path}DoesLiveInUSAnswerId` as keyof Settings],
            drPrescribed: +this.settings[`${this.hub.path}DrPrescribedAnswerId` as keyof Settings],
            noReimbursement: +this.settings[`${this.hub.path}NoReimbursementAnswerId` as keyof Settings],
            notFederal: +this.settings[`${this.hub.path}NotFederalAnswerId` as keyof Settings],
        };
        const eligibilityData = this[`${this.hub.path}EligibilityData` as keyof EligibilityModule];

        const isEligible = Object.entries(eligibilityData).every(
            ([prop, obj]: [keyof correctAnswers, SurveySessionValueModel]) => correctAnswers[prop] === obj.answerId
        );
        this.updateIsEligible(isEligible);
        return isEligible;
    }
}
