








































































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Resolve } from 'vue-di';
import theme from '~/settings/theme';
import Header from '~/components/Header/Header.vue';
import FloatingFooter from '~/components/floating-footer.vue';

@Component<DefaultLayout>({
    components: {
        Header,
        FloatingFooter,
    },
    mounted() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((this as any).$meta) (this as any).$meta().refresh();
    },
})
export default class DefaultLayout extends Vue {
    public theme = theme;
    public back() {
        this.$router.back();
    }

    handleClick() {
        this.$vuetify.goTo('#main-footer', {
            duration: 1000,
            easing: 'easeInOutCubic',
            offset: 0,
        });
    }
    public get brkPoints() {
        return {
            'brk-xs': this.$vuetify.breakpoint.xs,
            'brk-xs-only': this.$vuetify.breakpoint.xsOnly,
            'brk-sm': this.$vuetify.breakpoint.sm,
            'brk-sm-only': this.$vuetify.breakpoint.smOnly,
            'brk-sm-and-down': this.$vuetify.breakpoint.smAndDown,
            'brk-sm-and-up': this.$vuetify.breakpoint.smAndUp,
            'brk-md': this.$vuetify.breakpoint.md,
            'brk-md-only': this.$vuetify.breakpoint.mdOnly,
            'brk-md-and-down': this.$vuetify.breakpoint.mdAndDown,
            'brk-md-and-up': this.$vuetify.breakpoint.mdAndUp,
            'brk-lg': this.$vuetify.breakpoint.lg,
            'brk-lg-only': this.$vuetify.breakpoint.lgOnly,
            'brk-lg-and-down': this.$vuetify.breakpoint.lgAndDown,
            'brk-lg-and-up': this.$vuetify.breakpoint.lgAndUp,
            'brk-xl': this.$vuetify.breakpoint.xl,
            'brk-xl-only': this.$vuetify.breakpoint.xlOnly,
        };
    }
}
