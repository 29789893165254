import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a2d5f660 = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _907edeb6 = () => interopDefault(import('..\\pages\\hub\\index.vue' /* webpackChunkName: "pages_hub_index" */))
const _11756895 = () => interopDefault(import('..\\pages\\patient\\index.vue' /* webpackChunkName: "pages_patient_index" */))
const _2fd50af5 = () => interopDefault(import('..\\pages\\print.vue' /* webpackChunkName: "pages_print" */))
const _24bb3e51 = () => interopDefault(import('..\\pages\\rejection.vue' /* webpackChunkName: "pages_rejection" */))
const _dc27dca8 = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _173f5f71 = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _174d76f2 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _1769a5f4 = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _1777bd75 = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _4c259ab2 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _0f203e24 = () => interopDefault(import('..\\pages\\hub\\have-a-card\\index.vue' /* webpackChunkName: "pages_hub_have-a-card_index" */))
const _077d6efe = () => interopDefault(import('..\\pages\\patient\\have-a-card\\index.vue' /* webpackChunkName: "pages_patient_have-a-card_index" */))
const _2f475329 = () => interopDefault(import('..\\pages\\hub\\have-a-card\\eligibility.vue' /* webpackChunkName: "pages_hub_have-a-card_eligibility" */))
const _576a0b00 = () => interopDefault(import('..\\pages\\hub\\have-a-card\\patient-information.vue' /* webpackChunkName: "pages_hub_have-a-card_patient-information" */))
const _3f43669f = () => interopDefault(import('..\\pages\\hub\\have-a-card\\success.vue' /* webpackChunkName: "pages_hub_have-a-card_success" */))
const _15d2f057 = () => interopDefault(import('..\\pages\\hub\\need-a-card\\eligibility.vue' /* webpackChunkName: "pages_hub_need-a-card_eligibility" */))
const _c794eca4 = () => interopDefault(import('..\\pages\\hub\\need-a-card\\patient-information.vue' /* webpackChunkName: "pages_hub_need-a-card_patient-information" */))
const _5498cacd = () => interopDefault(import('..\\pages\\hub\\need-a-card\\success.vue' /* webpackChunkName: "pages_hub_need-a-card_success" */))
const _03d61539 = () => interopDefault(import('..\\pages\\patient\\have-a-card\\eligibility.vue' /* webpackChunkName: "pages_patient_have-a-card_eligibility" */))
const _5b3eac90 = () => interopDefault(import('..\\pages\\patient\\have-a-card\\patient-information.vue' /* webpackChunkName: "pages_patient_have-a-card_patient-information" */))
const _7db49ea2 = () => interopDefault(import('..\\pages\\patient\\have-a-card\\success.vue' /* webpackChunkName: "pages_patient_have-a-card_success" */))
const _2b3c9b32 = () => interopDefault(import('..\\pages\\patient\\need-a-card\\eligibility.vue' /* webpackChunkName: "pages_patient_need-a-card_eligibility" */))
const _23293bbe = () => interopDefault(import('..\\pages\\patient\\need-a-card\\patient-information.vue' /* webpackChunkName: "pages_patient_need-a-card_patient-information" */))
const _5309d646 = () => interopDefault(import('..\\pages\\patient\\need-a-card\\success.vue' /* webpackChunkName: "pages_patient_need-a-card_success" */))
const _6acee69a = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _a2d5f660,
    name: "error"
  }, {
    path: "/hub",
    component: _907edeb6,
    name: "hub"
  }, {
    path: "/patient",
    component: _11756895,
    name: "patient"
  }, {
    path: "/print",
    component: _2fd50af5,
    name: "print"
  }, {
    path: "/rejection",
    component: _24bb3e51,
    name: "rejection"
  }, {
    path: "/unlock",
    component: _dc27dca8,
    name: "unlock"
  }, {
    path: "/errors/400",
    component: _173f5f71,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _174d76f2,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _1769a5f4,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _1777bd75,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _4c259ab2,
    name: "errors-500"
  }, {
    path: "/hub/have-a-card",
    component: _0f203e24,
    name: "hub-have-a-card"
  }, {
    path: "/patient/have-a-card",
    component: _077d6efe,
    name: "patient-have-a-card"
  }, {
    path: "/hub/have-a-card/eligibility",
    component: _2f475329,
    name: "hub-have-a-card-eligibility"
  }, {
    path: "/hub/have-a-card/patient-information",
    component: _576a0b00,
    name: "hub-have-a-card-patient-information"
  }, {
    path: "/hub/have-a-card/success",
    component: _3f43669f,
    name: "hub-have-a-card-success"
  }, {
    path: "/hub/need-a-card/eligibility",
    component: _15d2f057,
    name: "hub-need-a-card-eligibility"
  }, {
    path: "/hub/need-a-card/patient-information",
    component: _c794eca4,
    name: "hub-need-a-card-patient-information"
  }, {
    path: "/hub/need-a-card/success",
    component: _5498cacd,
    name: "hub-need-a-card-success"
  }, {
    path: "/patient/have-a-card/eligibility",
    component: _03d61539,
    name: "patient-have-a-card-eligibility"
  }, {
    path: "/patient/have-a-card/patient-information",
    component: _5b3eac90,
    name: "patient-have-a-card-patient-information"
  }, {
    path: "/patient/have-a-card/success",
    component: _7db49ea2,
    name: "patient-have-a-card-success"
  }, {
    path: "/patient/need-a-card/eligibility",
    component: _2b3c9b32,
    name: "patient-need-a-card-eligibility"
  }, {
    path: "/patient/need-a-card/patient-information",
    component: _23293bbe,
    name: "patient-need-a-card-patient-information"
  }, {
    path: "/patient/need-a-card/success",
    component: _5309d646,
    name: "patient-need-a-card-success"
  }, {
    path: "/",
    component: _6acee69a,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
