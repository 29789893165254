

































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component<Header>({})
export default class Header extends Vue {
    @Prop()
    public brkPoints!: object;

    handleClick() {
        this.$vuetify.goTo('#static-footer', {
            duration: 1000,
            easing: 'easeInOutCubic',
            offset: 70,
        });
    }
}
