import colors from 'vuetify/es5/util/colors';
// import { Theme } from 'vuetify/src/services/theme';
import { Theme } from 'vuetify/types/services/theme';

export default ({
    dark: false,
    themes: {
        light: {
            primary: '#004e59',
            primaryLight: '#004e4f',
            secondary: '#fce200',
            accent: '#76bc21',
            error: colors.red.lighten2,
            warning: colors.amber.lighten2,
            info: colors.lightBlue.lighten2,
            success: colors.green.lighten2,
        },
        dark: {
            primary: '#004e59',
            primaryLight: '#004e4f',
            secondary: '#fce200',
            accent: '#76bc21',
            error: colors.red.darken2,
            warning: colors.amber.darken2,
            info: colors.lightBlue.darken2,
            success: colors.green.darken2,
        },
    },
} as unknown) as Theme;
