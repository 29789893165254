import { Mutation, Action } from 'vuex-module-decorators';
import { Resolve } from 'vue-di';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { EdgePatientEnrollmentApi } from '@trialcard/apigateway.client/edgePatientEnrollmentApi';
import { EdgeCouponApi } from '@trialcard/apigateway.client/edgeCouponApi';

import {
    EnrollPatientRequestModel,
    EnterpriseCoordinatorAccountUpsearchRequestModel,
    UpsearchModelEnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
    UpsearchModelEnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel,
    EnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
    EnterpriseCoordinatorSharedAddressUpsearchModel,
    EnterpriseCoordinatorSharedEmailAddressUpsearchModel,
    EnterpriseCoordinatorSharedPhoneUpsearchModel,
    GetBestEnrollmentV2RequestModel,
    EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel,
    EnterpriseCoordinatorAccountServiceModelsAccountExternalIdModel,
    EnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel,
    EnterpriseCoordinatorAccountServiceModelsAccountContactUpsearchModel,
    CouponMemberMemberNumberStatusWithProgramMemberNumberStatusWithProgramRequest,
    CouponMemberMemberNumberStatusWithProgramMemberNumberStatusWithProgramResponse
} from '@trialcard/apigateway.models';
import { Gender, AccountExternalIdType } from '@trialcard/enums';
import { OptimizeRxRequestModel } from '~/models/optimize-rx';

import moment, { Moment } from 'moment';
import axios, { AxiosResponse } from 'axios';
import { EdgeEnrollmentApi } from '@trialcard/apigateway.client';
import SessionModule from './session';
import { OnClear } from '~/services/clearModule';
import { Settings } from '~/services/settings';

import SurveyModule from '~/store/survey';
import ActivationModule from '~/store/activation';

@InjectModule({ stateFactory: true }, module)
export default class EnrollmentModule extends InjectVuexModule {
    @Resolve
    public patientEnrollmentApi!: EdgePatientEnrollmentApi;

    @Resolve
    public couponApi!: EdgeCouponApi;

    @Resolve
    public enrollmentApi!: EdgeEnrollmentApi;

    @Resolve
    public settings!: Settings;

    @Resolve
    public survey!: SurveyModule;

    @Resolve
    public session!: SessionModule;

    baseUrl = process.env.baseUrl;

    @Resolve
    public activation!: ActivationModule;

    alternativeEnrollment: boolean | null = null;

    memberNumber: string | null = null;

    expirationDate: string | null = null;

    isCareGiver: boolean | null = null;

    isMarketingOptIn: boolean | null = null;



    defaultPatientInformationData = {
        firstName: '' as string,
        lastName: '' as string,
        dateOfBirth: null as Moment | null,
        addresses: [] as EnterpriseCoordinatorSharedAddressUpsearchModel[],
        emailAddresses: [] as EnterpriseCoordinatorSharedEmailAddressUpsearchModel[],
        phoneNumbers: [] as EnterpriseCoordinatorSharedPhoneUpsearchModel[],
        gender: null as Gender | null,
    };

    defaultContacts = [] as EnterpriseCoordinatorAccountServiceModelsAccountContactUpsearchModel[];

    defaultContactPreferences = [] as EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel[];

    defaultCareGiverInformationData = {
        firstName: '' as string,
        lastName: '' as string,
        addresses: [] as EnterpriseCoordinatorSharedAddressUpsearchModel[],
        emailAddresses: [] as EnterpriseCoordinatorSharedEmailAddressUpsearchModel[],
        phoneNumbers: [] as EnterpriseCoordinatorSharedPhoneUpsearchModel[],
    };

    patientInformationData = { ...this.defaultPatientInformationData };
    contacts = [...this.defaultContacts];
    contactPreferences = [...this.defaultContactPreferences];
    careGiverInformationData = { ...this.defaultCareGiverInformationData };

    @Mutation
    public setEnrollmentType(alternative: boolean) {
        this.alternativeEnrollment = alternative;
    }

    @Mutation
    public async updatePatientInformation(newPatientInformation: typeof EnrollmentModule.prototype.patientInformationData) {
        this.patientInformationData = { ...this.patientInformationData, ...newPatientInformation };
    }

    @Mutation
    public async updateContacts(newContacts: EnterpriseCoordinatorAccountServiceModelsAccountContactUpsearchModel[]) {
        this.contacts = newContacts;
    }

    @Mutation
    public async updateContactPreferences(newContactPreferences: EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel[]) {
        this.contactPreferences = newContactPreferences;
    }
    
    @Mutation
    public async updateCareGiverInformation(newCareGiverInformation: typeof EnrollmentModule.prototype.careGiverInformationData) {
        this.careGiverInformationData = newCareGiverInformation;
    }

    @Mutation
    public setMemberNumber(memberNumber: string | null) {
        this.memberNumber = memberNumber;
    }

    @Mutation
    public setExpirationDate(expirationDate: Moment | string | null) {
        if (expirationDate && typeof expirationDate !== 'string') {
            expirationDate = expirationDate.format('L');
        }
        this.expirationDate = expirationDate;
    }

    @Mutation
    public setIsCaregiver(isCareGiver: boolean | null) {
        this.isCareGiver = isCareGiver;
    }

    @Mutation
    public setIsMarketingOptIn(isMarketingOptIn: boolean | null) {
        this.isMarketingOptIn = isMarketingOptIn;
    }

    @Action({ rawError: true })
    public clearStore() {
        this.setMemberNumber(null);
        this.setExpirationDate(null);
        this.setIsCaregiver(null);
        this.setIsMarketingOptIn(null);
        this.updatePatientInformation(this.defaultPatientInformationData);
        this.updateContacts(this.defaultContacts);
        this.updateContactPreferences(this.defaultContactPreferences);
        this.updateCareGiverInformation(this.defaultCareGiverInformationData);
    }

    @Action({ rawError: true })
    public clearEnrollment() {
        this.updatePatientInformation({ ...this.defaultPatientInformationData });
        this.setMemberNumber(null);
    }

    @Action({ rawError: true })
    public async checkForExistingEnrollments() {
        const patient: GetBestEnrollmentV2RequestModel = {
            firstName: this.patientInformationData.firstName as string,
            lastName: this.patientInformationData.lastName as string,
            dateOfBirth: this.patientInformationData.dateOfBirth as Moment,
            zip: this.patientInformationData.addresses[0].zip as string,
            gender: this.patientInformationData.gender as Gender,
        };
        const response = await this.enrollmentApi.enrollmentGetBestEnrollmentsV2(patient, this.settings.programId);
        return (response && response.data && response.data.data && response.data.data) || [];
    }

    getPromotionDesc(promId? : number){
        if(promId == 2339) {
            return "Valtoco Savings Program";
        }
        return "";
    } 

    getOfferTypeDesc(offerId? : number){
        if(offerId == 6487) {
            return "VALTOCO Physical Offers";
        }
        if(offerId == 6544) {
            return "Valtoco Patient NAC Web Referrals";
        }
        if(offerId == 6545) {
            return "Valtoco HUB NAC Web Referral";
        }
        return "";
    } 

    @Action({ rawError: true })
    public async submitConfirmation(data: { memberNumber: string | undefined; createDate: Moment | null | undefined }) {
        
        let AuthToken;
        let memberProgramStatus;
        let memberNumberStatus = {
            "memberId": data.memberNumber 
        } as CouponMemberMemberNumberStatusWithProgramMemberNumberStatusWithProgramRequest;
        
        let reponseMemberNumberStatus: AxiosResponse<CouponMemberMemberNumberStatusWithProgramMemberNumberStatusWithProgramResponse> = await this.couponApi.memberMemberNumberStatusWithProgram(memberNumberStatus, this.settings.programId);
        
        if(reponseMemberNumberStatus.data.success = true) {
            memberProgramStatus = reponseMemberNumberStatus.data.data;
        }

        await axios.post(this.settings.fingerpaintAuthApiUrl, null, { params: {
            client_id: this.settings.clientId,
            client_secret: this.settings.clientSecret
        }}).then( (response) => {
            AuthToken = response.data.access_token;
        }).catch(error => console.error('Error:', error.message));

        let promotionDesc = this.getPromotionDesc(memberProgramStatus?.promotionId);
        let offerTypeDesc = this.getOfferTypeDesc(memberProgramStatus?.offerTypeId);
        let memberNo = Number(data.memberNumber)
        const requestData: Partial<OptimizeRxRequestModel> = {
            PROMOTION_ID: memberProgramStatus?.promotionId,
            PROMOTION_DESCR: promotionDesc,
            OFFER_TYPE_ID: memberProgramStatus?.offerTypeId,
            OFFER_TYPE_DESCR: offerTypeDesc,
            PHARMACY_MEMBER_ID: memberNo,
            CARD_ACTIVATION_DT: (data.createDate || moment.utc()).format(moment.HTML5_FMT.DATE),      
            PATIENT_FIRST_NAME: this.patientInformationData.firstName,
            PATIENT_LAST_NAME:  this.patientInformationData.lastName,
            PATIENT_ADDR_LINE_1: this.patientInformationData.addresses[0]?.addressOne ,
            PATIENT_ADDR_LINE_2: this.patientInformationData.addresses[0]?.addressTwo,
            PATIENT_ADDR_CITY_NAME: this.patientInformationData.addresses[0]?.city,
            PATIENT_ADDR_ST_CD: this.patientInformationData.addresses[0]?.state,
            PATIENT_ADDR_POSTAL_CD: this.patientInformationData.addresses[0]?.zip,
            PATIENT_GENDER_CD: ((this.patientInformationData.gender || 3) <= 2 ? Gender[this.patientInformationData.gender || 0].toString() : 'U'),
            PATIENT_BIRTH_DT: this.patientInformationData.dateOfBirth?.format(moment.HTML5_FMT.DATE),
            PATIENT_CONTACT_EMAIL:  this.patientInformationData.emailAddresses[0]?.address,
            OPT_IN: this.isMarketingOptIn,
            CARE_PARTNER_SURVEY: this.isCareGiver,
            CARE_PARTNER_FIRST_NAME: this.careGiverInformationData.firstName,
            CARE_PARTNER_LAST_NAME: this.careGiverInformationData.lastName,
            CARE_PARTNER_EMAIL: this.careGiverInformationData.emailAddresses[0]?.address,
            CARE_PARTNER_PHONE: this.careGiverInformationData.phoneNumbers[0]?.phoneNumber,
            OPT_IN_DT: (data.createDate || moment.utc()).format(moment.HTML5_FMT.DATE),
        };
       
        await axios.post(this.settings.pharmaApiUrl, requestData, { headers: { 
            Authorization: 'Bearer '+AuthToken
        }})
        .catch(error => console.error('Error:', error.message));
    }

    @Action({ rawError: true })
    public async submitToAPI() {
        let response = null;
        const externalIds = this.activation.memberNumber
            ? [
                  {
                      externalIdType: AccountExternalIdType.PharMemberID,
                      externalId: this.activation.memberNumber,
                  },
              ]
            : ([] as EnterpriseCoordinatorAccountServiceModelsAccountExternalIdModel[]);
        try {
            const patient = {
                account: {
                    patientData: {
                        canUpdate: true,
                        canInsert: true,
                        skipSearch: false,
                        model: {
                            ...this.patientInformationData,
                            middleName: '',
                            suffix: '',
                            nickname: '',
                        } as EnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
                    } as UpsearchModelEnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
                    accountData: {
                        canUpdate: true,
                        canInsert: true,
                        skipSearch: false,
                        model: {
                            accountType: 1,
                            accountStatus: 1,
                            contacts: this.contacts,
                            contactPreferences: this.contactPreferences,
                            externalIds,
                        } as EnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel,
                    } as UpsearchModelEnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel,
                    surveySessionData: [Number(this.survey.sessionId)] as number[],
                } as EnterpriseCoordinatorAccountUpsearchRequestModel,
            } as EnrollPatientRequestModel;
            response = await this.patientEnrollmentApi.patientEnrollmentEnrollPatient(patient, this.settings.programId);
            
            let memberNumber = response.data.data?.enrollment?.memberNumbers[0]?.number;
            let createDate = response.data.data?.enrollment?.createDate;
            const optedIn = this.isMarketingOptIn;
            if (optedIn && response.status - (response.status % 100) === 200) {
                this.submitConfirmation({ memberNumber, createDate });
            }
        } catch (error) {
            console.log('Error in enrollment call', error);
            response = { status: 400 } as AxiosResponse;
        }
        return response;
    }
}
